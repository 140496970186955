<template>
    <v-container fill-height fluid class="black d-flex align-stretch justify-center">
        <v-row class=""  justify="center" no-gutters>
        <v-col cols="12" md="11" class="py-0 px-0 fill-height" fill-height style="display: block; min-height: 700px">
                <menus/>
            <v-row justify="start" class="px-0 py-0 mt-5" >
                <!-- Contenido de la izquierda-->
                <v-col cols="12" md="7"  class=" white--text py-0 pl-md-15" align="start" justify="start">
                    <h1 class="font-weight-black mb-5">
                            GALER&Iacute;A
                        </h1>
                        <v-spacer/>
                        <h4 class="text-md-left text-center mb-10">
                            ¿BUSCANDO INSPIRACIÓN?
                            <br/>
                            CHECA LO QUE ESTÁN SUBIENDO LOS DEMÁS
                        </h4>
                    <v-row>
                        <v-col 
                            cols="6" 
                            md="4"
                            v-for="(item, i) in galeria2"
                            :key="i"
                            @click="screen == 'window'?cambiarImagen(i): dialog = !dialog; cambiarImagen(i) "
                            class="click"
                        >
                            <participantes 
                                tipo="2"
                                :urlimage="item.url_image"
                                :nombre="item.complete_name"
                            />
                        </v-col>
                    </v-row>
                    <div class="d-flex justify-center" width="100%">
                    <v-btn class="becks my-10 " rounded @click="rellenar()">
                        VER M&Aacute;S
                    </v-btn>
                    </div>
                </v-col> 
                <!-- Contenido de la derecha-->   
                <v-col cols="12" md="4" max-width="350px" class="px-0 py-0 fixeado d-none d-md-inline" id="cuadroParticipacion">
                    <v-card rounded="xl" width="85%" max-width="300px" dark outlined style="border: white solid 1px" color="#373737" class="mb-5 mx-auto">
                        <v-responsive  align="center" width="100%" :aspect-ratio="9/16" class="justify-center align-center text-center " rounded="xl">
                            <v-img
                                contain
                                :aspect-ratio="9/16"
                                min-width="200"
                                class=""
                                :src="imagenGrande"
                            >
                            </v-img>
                        </v-responsive>
                        <v-card-actions class="txt_c">
                                {{nombreGrande}}
                        </v-card-actions>
                    </v-card>
                    <div class="text-center" width="100%"  >
                        <v-btn icon dark large @click="cambiarIndice(0)">
                            <v-icon x-large color="becks">mdi-chevron-left</v-icon>
                            <v-icon x-large class="ml-n4" color="becks">mdi-chevron-left</v-icon>
                        </v-btn>
                        <v-btn icon dark large @click="cambiarIndice(indice - 1)">
                            <v-icon x-large>mdi-chevron-left</v-icon>
                        </v-btn>
                        <v-btn icon dark large @click="cambiarIndice(indice + 1)">
                            <v-icon x-large>mdi-chevron-right</v-icon>
                        </v-btn>
                        <v-btn icon dark large @click="cambiarIndice(galeria.length-1)">
                            <v-icon x-large color="becks">mdi-chevron-right</v-icon>
                            <v-icon x-large class="ml-n4" color="becks">mdi-chevron-right</v-icon>
                        </v-btn>
                    </div>
                </v-col>
                <!--modal del contenido de la derecha para mobile-->
                <v-dialog
                    fullscreen
                    persistent
                    v-model="dialog"
                    class="black"
                >   <v-card
                        color="black"
                        class="text-center"
                    >
                        <v-card rounded="xl" width="85%" dark outlined style="border: white solid 1px" color="#373737" class="mb-5 mx-auto mt-5">
                            <v-responsive  align="center" width="100%" :aspect-ratio="9/16" class="justify-center align-center text-center ">
                                <v-img
                                    contain
                                    :aspect-ratio="9/16"
                                    min-width="200"
                                    class=""
                                    :src="imagenGrande"
                                >
                                </v-img>
                            </v-responsive>
                            <v-card-actions class="txt_c">
                                    {{nombreGrande}}
                            </v-card-actions>
                        </v-card>
                        <div class="text-center d-inline" width="100%" >
                            <v-btn icon dark large @click="cambiarIndice(0)">
                                <v-icon x-large color="becks">mdi-chevron-left</v-icon>
                                <v-icon x-large class="ml-n4" color="becks">mdi-chevron-left</v-icon>
                            </v-btn>
                            <v-btn icon dark large @click="cambiarIndice(indice - 1)">
                                <v-icon x-large>mdi-chevron-left</v-icon>
                            </v-btn>
                            <v-btn icon dark large @click="cambiarIndice(indice + 1)">
                                <v-icon x-large>mdi-chevron-right</v-icon>
                            </v-btn>
                            <v-btn icon dark large @click="cambiarIndice(galeria2.length-1)">
                                <v-icon x-large color="becks">mdi-chevron-right</v-icon>
                                <v-icon x-large class="ml-n4" color="becks">mdi-chevron-right</v-icon>
                            </v-btn>
                        </div>
                        <v-spacer/>
                        <v-btn class="becks my-10 mx-auto" rounded @click="dialog = !dialog">
                            REGRESAR
                        </v-btn>
                        <v-spacer/>
                    </v-card>
                </v-dialog>
            </v-row>
        </v-col>

    </v-row>
    </v-container>

</template>
<script>

    import menus from '../components/menus.vue'
    import participantes from '../components/participantes.vue'
    import axios from 'axios'
    
    export default {
        name: 'Fiestas',
        components: {
            menus,
            participantes
        },
        data: () => ({
            controlador: 0,
            galeria2: [],
            galeria: [],
            imagenGrande: '',
            nombreGrande:'',
            archivophp: 'galeria.php',
            indice: 0,
            screen: '',
            dialog: false
        }),
        methods: {
            cambiarIndice(num){
                if(num<=this.galeria.length && num >= 0){
                    this.cambiarImagen(num)
                }
            },
            rellenar(){
                for (let index = 0; index < 6; index++) {
                    if(this.controlador >= 0){
                        this.galeria2.push(this.galeria[this.controlador])
                        this.controlador--
                    }
                } 
            },
            cambiarImagen(indice){
                this.imagenGrande = this.galeria2[indice].url_image
                this.nombreGrande = this.galeria2[indice].complete_name
                this.indice = indice
            },
            async iniciar(){
                const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
                this.screen = width > 960 ? 'window' : 'mobile'

                // mandamos a pedir todas las fotos del usuario
                await axios.post(this.archivophp,{
                
                    }).then(function(response){

                        // console.log(response.data)
                        sessionStorage.array_galeria = JSON.stringify(response.data)

                    }).catch(error => {

                    })

                // aqui se imprimen un array de todas las fotos del usuario en sesion
                this.galeria = JSON.parse(sessionStorage.array_galeria)
                if(this.$route.params.uuid != null){
                    this.imagenGrande = '/imagen/archivosFinales/'+this.$route.params.uuid
                    this.nombreGrande = "Imagen Compartida"
                    if(this.screen == "mobile"){
                        this.dialog = true
                    }
                }else{
                    this.imagenGrande = this.galeria[this.galeria.length - 1].url_image
                    this.nombreGrande = this.galeria[this.galeria.length - 1].complete_name
                }
                this.controlador = this.galeria.length - 1
                this.rellenar()
            },
            scrollImage(){

                window.onscroll = function() {
                        let scrollTop = window.scrollY;
                        let docHeight = document.body.offsetHeight;
                        let winHeight = window.innerHeight;
                        let scrollPercent = scrollTop / (docHeight - winHeight);
                        let scrollPercentRounded = Math.round(scrollPercent * 100);

                        if(scrollPercentRounded >= 80){
                            document.querySelector("#cuadroParticipacion").style.position = "absolute";
                        }
                    };
                }
        },
        created(){
            if(this.$route.params.uuid != null){
                    this.archivophp = "../galeria.php"
                }
            this.iniciar()
            this.scrollImage();
        },
        mounted(){
        }
    }
</script>
<style>
.click{
    cursor: pointer;
}
.fixeado{
    position: fixed;
    right: 10%;
    top: 25%;
}
.txt_c{
    justify-content: left;
    text-align: left;
}
</style>
