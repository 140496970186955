<template>
<div>
    <v-card rounded="0" max-width="250" dark outlined  color="black" class="rounded-t-xl" v-if="tipo == '1'">
        <v-responsive  align="center" max-width="250" height="100%" class="justify-center text-center rounded-xl" style="border: white solid 1px">
            <v-img
                :aspect-ratio="1"
                width="100%"
                min-width="200"
                class=""
                :src="urlimage"
            >
            </v-img>
        </v-responsive>
        <v-card-actions class="txt_c">
                {{fecha}}
        </v-card-actions>
    </v-card>
    <v-card rounded="xl" max-width="250" dark outlined style="border: white solid 1px" color="#373737" v-if="tipo == '2'">
        <v-responsive  align="center" max-width="250" height="100%" class="justify-center text-center " rounded="xl">
            <v-img
                
                :aspect-ratio="1"
                width="100%"
                min-width="200"
                class=""
                :src="urlimage"
            >
            </v-img>
            <v-card-actions class="txt_c">
                {{nombre}}
            </v-card-actions>
        </v-responsive>
    </v-card>
</div>
</template>
<script>

export default {
    name: 'participantes',
    props:[
        'tipo',
        'urlimage',
        'nombre',
        'fecha'
    ],
    data: () => ({
        
    }),
    methods:{
    }
}
</script>
<style scoped>
.txt_c{
    justify-content: left;
    text-align: left;
}
</style>